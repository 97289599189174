export default [
  // cms/homebanner
  {
    path: '/cms/homebanner',
    name: 'cms-homebanner',
    component: () => import('@/views/cms/homebanner/HomeBanner.vue'),
    meta: {
      pageTitle: 'Home Banner',
      breadcrumb: [
        {
          text: 'Home Banner',
          to: '/cms/homebanner',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'homebanner',
    },
  },
  // cms/social
  {
    path: '/cms/social',
    name: 'cms-social',
    component: () => import('@/views/cms/social/Social.vue'),
    meta: {
      pageTitle: 'Social',
      breadcrumb: [
        {
          text: 'Social',
          to: '/cms/social',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'social',
    },
  },
  // cms/feedback
  {
    path: '/cms/feedback',
    name: 'cms-feedback',
    component: () => import('@/views/cms/feedback/Feedback.vue'),
    meta: {
      pageTitle: 'Feedback',
      breadcrumb: [
        {
          text: 'Feedback',
          to: '/cms/feedback',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'feedback',
    },
  },
  // cms/article
  {
    path: '/cms/article',
    name: 'cms-article',
    component: () => import('@/views/cms/article/Article.vue'),
    meta: {
      pageTitle: 'Article',
      breadcrumb: [
        {
          text: 'Article',
          to: '/cms/article',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'article',
    },
  },
  // cms/faq
  {
    path: '/cms/faq',
    name: 'cms-faq',
    component: () => import('@/views/cms/faq/Faq.vue'),
    meta: {
      pageTitle: 'Faq',
      breadcrumb: [
        {
          text: 'Faq',
          to: '/cms/faq',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'faq',
    },
  },
  // cms/content
  {
    path: '/cms/content',
    name: 'cms-content',
    component: () => import('@/views/cms/content/Content.vue'),
    meta: {
      pageTitle: 'Content',
      breadcrumb: [
        {
          text: 'Content',
          to: '/cms/content',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'content',
    },
  },
  // cms/property
  {
    path: '/cms/property',
    name: 'cms-property',
    component: () => import('@/views/cms/property/Property.vue'),
    meta: {
      pageTitle: 'Property',
      breadcrumb: [
        {
          text: 'Property',
          to: '/cms/property',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'property',
    },
  },
  // cms/auction
  {
    path: '/cms/auction',
    name: 'cms-auction',
    component: () => import('@/views/cms/auction/Auction.vue'),
    meta: {
      pageTitle: 'Auction',
      breadcrumb: [
        {
          text: 'Auction',
          to: '/cms/auction',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'auction',
    },
  },
  // cms/auctionbanner
  {
    path: '/cms/auctionbanner',
    name: 'cms-auctionbanner',
    component: () => import('@/views/cms/auctionbanner/AuctionBanner.vue'),
    meta: {
      pageTitle: 'Auction Banner',
      breadcrumb: [
        {
          text: 'Auction Banner',
          to: '/cms/auctionbanner',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'auctionbanner',
    },
  },
  // cms/sellerbanner
  {
    path: '/cms/sellerbanner',
    name: 'cms-sellerbanner',
    component: () => import('@/views/cms/sellerbanner/SellerBanner.vue'),
    meta: {
      pageTitle: 'Seller Banner',
      breadcrumb: [
        {
          text: 'Seller Banner',
          to: '/cms/sellerbanner',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'sellerbanner',
    },
  },
  // cms/buyerbanner
  {
    path: '/cms/buyerbanner',
    name: 'cms-buyerbanner',
    component: () => import('@/views/cms/buyerbanner/BuyerBanner.vue'),
    meta: {
      pageTitle: 'Buyer Banner',
      breadcrumb: [
        {
          text: 'Buyer Banner',
          to: '/cms/buyerbanner',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'buyerbanner',
    },
  },
  // cms/articlebanner
  {
    path: '/cms/articlebanner',
    name: 'cms-articlebanner',
    component: () => import('@/views/cms/articlebanner/ArticleBanner.vue'),
    meta: {
      pageTitle: 'Article Banner',
      breadcrumb: [
        {
          text: 'Article Banner',
          to: '/cms/articlebanner',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'articlebanner',
    },
  },
  // cms/contactbanner
  {
    path: '/cms/contactbanner',
    name: 'cms-contactbanner',
    component: () => import('@/views/cms/contactbanner/ContactBanner.vue'),
    meta: {
      pageTitle: 'Contact Banner',
      breadcrumb: [
        {
          text: 'Contact Banner',
          to: '/cms/contactbanner',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'contactbanner',
    },
  },
  // cms/servicebanner
  {
    path: '/cms/servicebanner',
    name: 'cms-servicebanner',
    component: () => import('@/views/cms/servicebanner/ServiceBanner.vue'),
    meta: {
      pageTitle: 'Service Banner',
      breadcrumb: [
        {
          text: 'Service Banner',
          to: '/cms/servicebanner',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'servicebanner',
    },
  },
]
