<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <div
      v-if="isShowAlertTopBar"
      no-body
      class="text-center"
      style="
        width: 100%;
        height: 28px;
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0.7;
        z-index: 999999;
        background-color: #000000;
        padding: 0px !important;
        vertical-align: middle;
        display: flex;
      "
    >
      <div
        style="width: 100%; color: #ffffff; font-size: 14px; text-align: center; padding: 4px;"
      >
        {{ $t('The Internet network cannot be connected') }}
      </div>
      <div
        style="padding: 3px 10px 3px 3px; color: #ffffff; cursor: pointer;"
        @click="onCloseAlertTopBar"
      >
        <feather-icon
          icon="XIcon"
          size="18"
        />
      </div>
    </div>
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

import elementTh from 'element-ui/lib/locale/lang/th'
import elementEn from 'element-ui/lib/locale/lang/en'
import elementLocale from 'element-ui/lib/locale'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  data() {
    return {
      isShowAlertTopBar: false,
      isNetworkError: false,
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
    this.$i18n.locale = localStorage.getItem('deefin-locale') || $themeConfig.layout.locale

    elementLocale.use(elementTh)
    if (this.$i18n.locale === 'en') {
      elementLocale.use(elementEn)
    }
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.timer = window.setInterval(() => {
        // console.log('heartbeat')
        this.checkHeartbeat()
      }, 60000)
    })
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    checkHeartbeat() {
      const routeName = this.$route.name
      // console.log(routeName)
      const routeMeta = this.$route.meta
      // console.log(routeMeta)
      store
        .dispatch('app/checkHeartbeat', { language: this.$i18n.locale, route: { name: routeName, meta: routeMeta } })
        .then(() => {
          // console.log(response)
          if (this.isNetworkError === true) {
            window.location.reload()
          }
          this.isShowAlertTopBar = false
          this.isNetworkError = false
        })
        .catch(() => {
          // const { message } = error
          // console.log(error.request.status)
          // console.log(message)
          this.isNetworkError = true
          this.isShowAlertTopBar = true
        })
    },
    onCloseAlertTopBar() {
      this.isShowAlertTopBar = false
    },
  },
}
</script>
