export default [
  // crm/lineuser
  {
    path: '/crm/lineuser/list',
    name: 'crm-lineuser-list',
    component: () => import('@/views/crm/lineuser/LineUserList.vue'),
    meta: {
      pageTitle: 'Line User',
      breadcrumb: [
        {
          text: 'Line User',
          to: '/crm/lineuser/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'lineuser',
    },
  },
  // crm/member
  {
    path: '/crm/member',
    name: 'crm-member',
    component: () => import('@/views/crm/member/Member.vue'),
    meta: {
      pageTitle: 'Member',
      breadcrumb: [
        {
          text: 'Member',
          to: '/crm/member',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'member',
    },
  },
  // crm/media
  {
    path: '/crm/media',
    name: 'crm-media',
    component: () => import('@/views/crm/media/Media.vue'),
    meta: {
      contentClass: 'media-crm',
      pageTitle: 'Media',
      breadcrumb: [
        {
          text: 'Media',
          to: '/crm/media',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
]
