export default [
  // // tools/lineuser
  // {
  //   path: '/tools/lineuser/list',
  //   name: 'tools-lineuser-list',
  //   component: () => import('@/views/tools/lineuser/LineUserList.vue'),
  //   meta: {
  //     pageTitle: 'Line User',
  //     breadcrumb: [
  //       {
  //         text: 'Line User',
  //         to: '/tools/lineuser/list',
  //         active: false,
  //       },
  //       {
  //         text: 'List',
  //         active: true,
  //       },
  //     ],
  //     contentClass: 'lineuser',
  //   },
  // },
  // tools/sharetarget
  {
    path: '/tools/sharetarget/list',
    name: 'tools-sharetarget-list',
    component: () => import('@/views/tools/sharetarget/ShareTargetList.vue'),
    meta: {
      pageTitle: 'Share Target',
      breadcrumb: [
        {
          text: 'Share Target',
          to: '/tools/sharetarget/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'sharetarget',
    },
  },
  // tools/linechannel
  {
    path: '/tools/linechannel/list',
    name: 'tools-linechannel-list',
    component: () => import('@/views/tools/linechannel/LineChannelList.vue'),
    meta: {
      pageTitle: 'LINE Channel',
      breadcrumb: [
        {
          text: 'LINE Channel',
          to: '/tools/linechannel/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'linechannel',
    },
  },
  // tools/lineliff
  {
    path: '/tools/lineliff/list',
    name: 'tools-lineliff-list',
    component: () => import('@/views/tools/lineliff/LineLiffList.vue'),
    meta: {
      pageTitle: 'LINE Liff',
      breadcrumb: [
        {
          text: 'LINE Liff',
          to: '/tools/lineliff/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'lineliff',
    },
  },
  // tools/sharetarget_transaction
  {
    path: '/tools/sharetarget_transaction/list',
    name: 'tools-sharetarget-transaction-list',
    component: () => import('@/views/tools/sharetarget_transaction/ShareTargetTransactionList.vue'),
    meta: {
      pageTitle: 'Share Target Transaction',
      breadcrumb: [
        {
          text: 'Share Target Transaction',
          to: '/tools/sharetarget_transaction/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'sharetarget-transaction',
    },
  },
  // tools/setting
  {
    path: '/tools/setting/list',
    name: 'tools-setting-list',
    component: () => import('@/views/tools/setting/SettingList.vue'),
    meta: {
      pageTitle: 'Setting',
      breadcrumb: [
        {
          text: 'Setting',
          to: '/tools/setting/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'setting',
    },
  },
]
